import { createWebHistory, createRouter } from "vue-router";

import DashboardView from "../views/DashboardView.vue";
import RosterView from "../views/RosterView.vue";
import ImpressumView from "../views/ImpressumView.vue";
import CharacterView from "../views/CharacterView.vue";
import PageNotFound from "../views/PageNotFoundView.vue";
import SettingsView from "../views/SettingsView.vue";
import LoginView from "../views/LoginView.vue";
import SettingsRosterMemberView from "../views/SettingsRosterMemberView.vue"
import SettingsRulesView from "../views/SettingsRulesView.vue";
import SettingsCharacterRulesView from "../views/SettingsCharacterRulesView.vue";
import DatenschutzView from "../views/DatenschutzView.vue";

const routes = [
  {
    path: "/",
    name: "Start",
    component: () => import('../views/DashboardView.vue')
  },
  {
    path: "/roster",
    name: "Roster",
    component: () => import('../views/RosterView.vue')

  },
  {
    path: "/character/:region/:realm/:name",
    name: "Character",
    component: () => import('../views/CharacterView.vue')
  },
  {
    path: "/impressum",
    name: "Impressum",
    component: () => import('../views/ImpressumView.vue')
  },
  {
    path: "/datenschutz",
    name: "Datenschutz",
    component: () => import('../views/DatenschutzView.vue')
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import('../views/SettingsView.vue')
  },
  {
    path: "/settings/roster",
    name: "/settings/roster",
    component: () => import('../views/SettingsRosterMemberView.vue')
  },
  {
    path: "/settings/rules",
    name: "/settings/rules",
    component: () => import('../views/SettingsRulesView.vue')
  },
  {
    path: "/settings/character",
    name: "/settings/character",
    component: () => import('../views/SettingsCharacterRulesView.vue')
  },
  {
    path: "/login",
    name: "Login",
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/:catchAll(.*)',
    name: "PageNotFound",
    component: () => import('../views/PageNotFoundView.vue')
  },


];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
