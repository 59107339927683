// FILE: main.js

import { createApp } from 'vue'
import router from './router'
import { Quasar } from 'quasar'

import VueApexCharts from "vue3-apexcharts";

// Import icon libraries
import '@quasar/extras/material-icons/material-icons.css'

// Import Quasar css
import 'quasar/src/css/index.sass'

// Import Custom scss
import './assets/main.scss'

// Import Custom css
import './assets/styles.css'

// Assumes your root component is App.vue
// and placed in same folder as main.js
import App from './App.vue'

const myApp = createApp(App)

myApp.use(VueApexCharts);

myApp.use(Quasar, {
    config: {
        dark: true/* look at QuasarConfOptions from the API card */
      },
    plugins: {}, // import Quasar plugins and add here
});

// Assumes you have a <div id="app"></div> in your index.html
myApp.use(router).mount('#app');
