<template>
    <q-footer elevated :class="$q.dark.isActive ? 'bg-grey-9' : 'bg-grey-3'">
      <q-toolbar>
        <q-toolbar-title>
         
        </q-toolbar-title>
        
        <q-btn flat label="Impressum" to="/impressum" />
        <q-btn flat label="Datenschutz" to="/datenschutz" />
        
      </q-toolbar>
    </q-footer>
</template>

<script>

export default {
setup () {
  return {
  }
}
}

</script>

