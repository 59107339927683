<template>
  <q-layout  view="hHh lpR lff">
    <HeaderView/>
    <q-page-container>
      <router-view />
      <FooterView/>
    </q-page-container>
  </q-layout>
</template>
  
<script>
  import HeaderView from './components/Header.vue';
  import FooterView from './components/Footer.vue'
  export default {
    components: {
      HeaderView,
      FooterView
    },
    created() {
    },

    data() {
      return {

      };
	  },
  }
	
</script>